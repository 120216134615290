import { gql } from '@apollo/client'
import { gql as contentfulGql } from '@apollo/client'

export const GET_COMING_SOON_PROJECT_DATA = gql`
  query getComingSoonProjectData($slug: String!) {
    project(slug: $slug) {
      id
      name
      slug
      projectType
      discoveryPosterCloudinaryPath
      public
      seasons {
        id
        seasonNumber
        episodes {
          id
          guid
          subtitle
          prereleaseAvailableFor
          userHasEarlyAccess
          earlyAccessDate
          posterCloudinaryPath
          publiclyAvailableDate
          unavailableReason
        }
      }
    }
  }
`

export const GET_PROJECT_CATALOG_IDS = contentfulGql/* GraphQL */ `
  query getProjectCatalogIds($preview: Boolean) {
    projectCollection(preview: $preview, limit: 50) {
      items {
        slug
        contentCatalogId
        hasHubPage
      }
    }
  }
`

export const GET_COMING_SOON_TAB = gql`
  query GetComingSoonTab($after: String, $preview: Boolean) {
    comingSoonTagGroup(slug: "all") {
      id
      slug
      title(version: SHORT)
      connection(first: 80, after: $after, preview: $preview) {
        edges {
          node {
            id
            header
            title
            subtitle
            focus {
              __typename
              type
              date
            }
            watchable {
              ... on Node {
                id
              }
              ... on ContentPreviewable {
                preview {
                  id
                  m3u8Uri
                  duration
                }
              }
              ... on ContentDisplayable {
                name
                description(version: SHORT)
                posterTitleImage: image(aspect: "2:3", category: TITLE_ART) {
                  aspect
                  category
                  cloudinaryPath
                }
                landscapeTitleImage: image(aspect: "16:9", category: TITLE_ART) {
                  aspect
                  category
                  cloudinaryPath
                }
                landscapeAngelImage: image(aspect: "16:9", category: ANGEL_KEY_ART_1) {
                  aspect
                  category
                  cloudinaryPath
                }
                landscapeStillImage: image(aspect: "16:9", category: STILL_1) {
                  aspect
                  category
                  cloudinaryPath
                }
              }
            }
            project {
              id
              slug
              name
              public
              logoCloudinaryPath
              discoveryPosterCloudinaryPath
              theaterDescription
              highestScore
              projectType
              seasons {
                id
                seasonNumber
                episodes {
                  id
                  guid
                  subtitle
                  episodeNumber
                }
              }
              metadata {
                contentRating
              }
            }
          }
          cursor
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`
