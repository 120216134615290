import React, { FC } from 'react'
import { Maybe } from '@graphql-tools/utils'
import classNames from 'classnames'
import { EyebrowMD } from '@/atoms/Text'
import { Paragraph } from '@/atoms/Typography'

interface Props extends React.HTMLAttributes<HTMLElement> {
  title?: string
  subtitle?: Maybe<string>
  duration?: number | null
  clampSubtitle?: boolean
}

export const TitleAndSubtitleDisplay: FC<Props> = ({
  duration = 0,
  title,
  subtitle,
  clampSubtitle = true,
  ...props
}) => {
  const formattedDuration = duration ? `${Math.round(duration / 60)}m` : ''

  return (
    <div {...props}>
      {title && (
        <div className="flex justify-between">
          <EyebrowMD
            weight="bold"
            color="core-gray-600"
            className="block overflow-x-hidden text-ellipsis whitespace-nowrap"
          >
            {title}
          </EyebrowMD>
          <EyebrowMD
            weight="bold"
            color="core-gray-600"
            className="block overflow-x-hidden text-ellipsis whitespace-nowrap lowercase"
          >
            {formattedDuration}
          </EyebrowMD>
        </div>
      )}
      {subtitle && (
        <Paragraph
          as={(p) => <h3 {...p} />}
          aria-label={subtitle}
          className={classNames('my-1 overflow-x-hidden', { 'text-ellipsis whitespace-nowrap': clampSubtitle })}
        >
          {subtitle}
        </Paragraph>
      )}
    </div>
  )
}
