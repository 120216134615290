import { Thumbnail } from '@/molecules/Thumbnail'
import { LinkViewProps } from '@/services/RenderService'
import { formatCloudinaryPath } from '@/utils/Cloudinary/cloudinary'

export function LandscapeLink(props: LinkViewProps) {
  return (
    <Thumbnail
      showTitle={false}
      className="mr-4"
      href={props.link.linkUrl}
      cloudinaryImagePath={formatCloudinaryPath(props.link.imageUrl) as string}
      title={props.link.alt}
    />
  )
}
