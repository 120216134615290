import { ApolloClient } from '@apollo/client'
import { paths } from '@/constants'
import { GetComingSoonTabQuery } from '@/types/codegen-federation'
import { AnalyticsEvent } from '@/utils/analytics'
import { formatOrderSuffix } from '@/utils/numbers'
import { TranslateFunction } from '@/utils/translate/translate-client'
import { isDefined } from '@/utils/types'
import { ContentfulClientOptions, getContentfulClient, getWebClient } from '../ApolloClient'
import { fetchTitles, formatTitleLikeVerticalImagePath } from '../ContentCatalog'
import { getProjectV2sComingSoon } from '../PhaseManagerService'
import { LinkViewModel } from '../RenderService'
import { GET_COMING_SOON_PROJECT_DATA, GET_COMING_SOON_TAB, GET_PROJECT_CATALOG_IDS } from './queries'
import { ComingSoonNode, ComingSoonProject, ComingSoonTitle } from './types'

export const getComingSoonProjects = async (locale: string) => {
  const client = getWebClient()
  const projectV2sComingSoon = await getProjectV2sComingSoon()

  const projects = await getProjectCatalogData()

  const comingSoonProjects = await Promise.all(
    projectV2sComingSoon?.map(async (item) => {
      const matchingProject = projects.find(
        (project: { slug: string | null | undefined }) => project?.slug === item?.slug,
      )

      const projectMeta = await getComingSoonProjectMeta(client, item?.slug)

      const guildAccessDate =
        item?.primaryFlow?.flowPhases?.find((phase) => phase?.phaseSlugEnum === 'guild_access')?.releaseWindows?.[0]
          ?.start ?? null
      const guildAccessSpecificity =
        item?.primaryFlow?.flowPhases?.find((phase) => phase?.phaseSlugEnum === 'guild_access')?.releaseWindows?.[0]
          ?.startSpecificity ?? null
      const theatricalReleaseDate =
        item?.primaryFlow?.flowPhases?.find((phase) => phase?.phaseSlugEnum === 'theatrical')?.releaseWindows?.[0]
          ?.start ?? null
      const theatricalReleaseSpecificity =
        item?.primaryFlow?.flowPhases?.find((phase) => phase?.phaseSlugEnum === 'theatrical')?.releaseWindows?.[0]
          ?.startSpecificity ?? null
      const theatricalEndDate =
        item?.primaryFlow?.flowPhases?.find((phase) => phase?.phaseSlugEnum === 'pre_sale')?.releaseWindows?.[0]?.end ??
        null
      const presaleDate =
        item?.primaryFlow?.flowPhases?.find((phase) => phase?.phaseSlugEnum === 'pre_sale')?.releaseWindows?.[0]
          ?.start ?? null

      return {
        ...item,
        project: matchingProject,
        meta: projectMeta,
        guildAccessDate,
        guildAccessSpecificity,
        theatricalReleaseDate,
        theatricalReleaseSpecificity,
        theatricalEndDate,
        presaleDate,
      }
    }) ?? [],
  )

  const allContentIds = mapAllComingSoonContentIds(comingSoonProjects)

  const titles = await fetchTitles(locale, allContentIds)

  const combinedProjects = comingSoonProjects.map((project) => {
    const matchingTitle = titles.find((title) => title?.id === project?.project?.contentCatalogId)
    return {
      ...project,
      title: matchingTitle,
    }
  })

  const sortedProjects = combinedProjects.sort((a, b) => {
    const now = Date.now()

    const aGuildAccessDate = new Date(a.guildAccessDate || 0).getTime()
    const bGuildAccessDate = new Date(b.guildAccessDate || 0).getTime()
    const aTheatricalReleaseDate = new Date(a.theatricalReleaseDate || 0).getTime()
    const bTheatricalReleaseDate = new Date(b.theatricalReleaseDate || 0).getTime()

    const aClosestDate = aGuildAccessDate || aTheatricalReleaseDate
    const bClosestDate = bGuildAccessDate || bTheatricalReleaseDate

    const aDifference = Math.abs(aClosestDate - now)
    const bDifference = Math.abs(bClosestDate - now)

    if (aClosestDate === 0 && bClosestDate === 0) {
      return 0
    }

    if (aClosestDate === 0) {
      return 1
    }
    if (bClosestDate === 0) {
      return -1
    }

    return aDifference - bDifference
  })

  return sortedProjects ?? []
}

export const getComingSoonTab = async () => {
  const comingSoonProjects = await getProjectsComingSoon()

  return comingSoonProjects
}

const getProjectCatalogData = async (opts?: ContentfulClientOptions) => {
  const client = getContentfulClient(opts)
  const { data } = await client.query({
    query: GET_PROJECT_CATALOG_IDS,
  })

  return data.projectCollection?.items
}

export function mapAllComingSoonContentIds(comingSoonProjects: ComingSoonProject[] | undefined) {
  return (
    comingSoonProjects
      ?.filter(isDefined)
      .map((release: ComingSoonProject) => release?.project?.contentCatalogId)
      .flat()
      .filter(isDefined) ?? []
  )
}

async function getComingSoonProjectMeta(client: ApolloClient<object>, slug?: string | undefined | null) {
  if (!slug) return {}
  const { data } = await client.query({
    query: GET_COMING_SOON_PROJECT_DATA,
    variables: { slug },
  })

  return data?.project ?? {}
}

const isTicketsPage = (item: ComingSoonTitle, now: number): boolean => {
  const presaleDate = new Date(item?.presaleDate || 0).getTime()
  const theatricalReleaseDate = new Date(item?.theatricalReleaseDate || 0).getTime()
  const theatricalEndDate = new Date(item?.theatricalEndDate || 0).getTime()

  return (presaleDate < now || theatricalReleaseDate < now) && theatricalEndDate > now
}

const isTicketsTabPage = (item: ComingSoonNode): boolean => {
  return item?.focus?.type === 'theatrical'
}

const getProjectSlug = (item: ComingSoonTitle): string | undefined => {
  return item?.slug ?? item?.project?.slug
}

const getProjectTabSlug = (item: ComingSoonNode): string | undefined => {
  return item?.project?.slug || undefined
}

const getLinkPath = (item: ComingSoonTitle, projectSlug: string): string => {
  if (item?.meta?.public) {
    return `${paths.watch.index}/${projectSlug}`
  } else if (item?.project?.hasHubPage && item?.meta?.projectType === 'movie') {
    return `${paths.movies.index}/${projectSlug}`
  } else if (item?.project?.hasHubPage && item?.meta?.projectType === 'series') {
    return `${paths.shows.index}/${projectSlug}`
  } else {
    return `${paths.guild.join}/${projectSlug}`
  }
}

const getLinkTabPath = (item: ComingSoonNode, projectSlug: string): string => {
  if (item?.watchable?.__typename === 'ContentEpisode' || item?.watchable?.__typename === 'ContentSpecial') {
    return `${paths.watch.index}/${projectSlug}/episode/${item?.watchable?.id}`
  } else if (item?.project?.public) {
    return `${paths.watch.index}/${projectSlug}`
  } else if (item?.project?.projectType === 'movie') {
    return `${paths.movies.index}/${projectSlug}`
  } else if (item?.project?.projectType === 'series') {
    return `${paths.shows.index}/${projectSlug}`
  } else {
    return `${paths.guild.join}/${projectSlug}`
  }
}

export const buildLinkUrl = (item: ComingSoonTitle): string => {
  const projectSlug = getProjectSlug(item)
  if (!projectSlug) {
    return `${paths.guild.join}`
  }

  const now = new Date().getTime()
  if (isTicketsPage(item, now)) {
    return `${paths.tickets.index}/${projectSlug}`
  }

  return getLinkPath(item, projectSlug)
}

export const buildLinkTabUrl = (item: ComingSoonNode): string => {
  const projectSlug = getProjectTabSlug(item)
  if (!projectSlug) {
    return `${paths.guild.join}`
  }

  if (isTicketsTabPage(item)) {
    return `${paths.tickets.index}/${projectSlug}`
  }

  return getLinkTabPath(item, projectSlug)
}

export const buildLinkModel = (item: ComingSoonTitle): LinkViewModel | null => {
  const imageUrl = item?.title && formatTitleLikeVerticalImagePath(item.title, ['2:3'])

  if (!imageUrl) return null

  const linkUrl = buildLinkUrl(item)
  const projectSlug = item?.slug ?? item?.project?.slug
  const title = item?.name
  const episodeNumber = item?.meta?.seasons?.[0]?.episodes?.[0]?.episodeNumber ?? 1
  const seasonNumber = item?.meta?.seasons?.[0]?.seasonNumber ?? 1
  const guid = item?.id

  return {
    alt: title ? `Link to show ${title}` : 'Link to content',
    label: title,
    imageUrl: imageUrl,
    linkUrl,
    track: {
      eventName: 'Coming Soon Thumbnail Clicked' as AnalyticsEvent,
      payload: {
        linkUrl,
        projectSlug,
        episodeNumber,
        guid,
        seasonNumber,
      },
    },
  }
}

export const buildLinkTabModel = (item: ComingSoonNode): LinkViewModel | null => {
  const imageUrl = item?.watchable && firstUsableLandscapePath(item.watchable)

  if (!imageUrl) return null

  const linkUrl = buildLinkTabUrl(item)
  const projectSlug = item?.project?.slug
  const title = item?.title || undefined
  const episodeNumber = item?.project?.seasons?.[0]?.episodes?.[0]?.episodeNumber ?? 1
  const seasonNumber = item?.project?.seasons?.[0]?.seasonNumber ?? 1
  const guid = item?.id

  return {
    alt: title ? `Link to show ${title}` : 'Link to content',
    label: title,
    imageUrl: imageUrl,
    linkUrl,
    track: {
      eventName: 'Coming Soon Thumbnail Clicked',
      payload: {
        linkUrl,
        projectSlug,
        episodeNumber,
        guid,
        seasonNumber,
      },
    },
  }
}

export const buildSubtitle = (item: ComingSoonTitle, t: TranslateFunction, locale: string): string => {
  if (item?.theatricalReleaseDate) {
    if (item?.theatricalReleaseSpecificity === 'P1M') {
      const releaseDate = new Date(item.theatricalReleaseDate)
      return t('inTheatersOn', 'In Theaters {{ date }}', {
        date: releaseDate.toLocaleString(locale, { month: 'short', year: 'numeric', timeZone: 'UTC' }),
      })
    } else {
      const releaseDate = new Date(item.theatricalReleaseDate)
      return t('inTheatersOn', 'In Theaters {{ date }}', {
        date:
          releaseDate.toLocaleString(locale, { month: 'short', day: 'numeric', timeZone: 'UTC' }) +
          (locale === 'en' ? formatOrderSuffix(releaseDate.getUTCDate()) : ''),
      })
    }
  } else if (item?.guildAccessDate) {
    if (item?.guildAccessSpecificity === 'P1M') {
      const releaseDate = new Date(item.guildAccessDate)
      return t('guildAccessOnDate', 'Guild Access {{ date }}', {
        date: releaseDate.toLocaleString(locale, { month: 'short', year: 'numeric', timeZone: 'UTC' }),
      })
    } else {
      const releaseDate = new Date(item.guildAccessDate)
      return t('guildAccessOnDate', 'Guild Access {{ date }}', {
        date:
          releaseDate.toLocaleString(locale, { month: 'short', day: 'numeric', timeZone: 'UTC' }) +
          (locale === 'en' ? formatOrderSuffix(releaseDate.getUTCDate()) : ''),
      })
    }
  } else return t('toBeAnnounced', 'To Be Announced')
}

export const buildTabSubtitle = (item: ComingSoonNode, t: TranslateFunction): string => {
  if (item?.focus && item.focus.type === 'guild-access') {
    return t('guildAccess', 'Guild Access')
  } else if (item?.focus?.type === 'theatrical') {
    return t('inTheaters', 'In Theaters')
  } else if (item?.header) {
    return t('coming', 'Coming')
  } else return t('toBeAnnounced', 'To Be Announced')
}

const getProjectsComingSoon = async (): Promise<ComingSoonNode[]> => {
  const client = getWebClient()
  const { data } = await client.query<GetComingSoonTabQuery>({
    query: GET_COMING_SOON_TAB,
    variables: { preview: false, after: null },
    errorPolicy: 'all',
  })
  if (!data?.comingSoonTagGroup?.connection?.edges) return []

  return data.comingSoonTagGroup.connection.edges
    .filter((edge) => !!edge?.node?.project?.slug)
    .map((edge) => edge?.node)
}

export const firstUsableLandscapePath = (watchable: NonNullable<ComingSoonNode>['watchable']) => {
  return (
    watchable.landscapeAngelImage?.cloudinaryPath ||
    watchable.landscapeTitleImage?.cloudinaryPath ||
    watchable.landscapeStillImage?.cloudinaryPath ||
    ''
  )
}

const monthAbbreviations: { [key: string]: string } = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sep',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec',
}

export const shortenMonthInHeader = (header: string): string => {
  return header.replace(
    /\b(January|February|March|April|May|June|July|August|September|October|November|December)\b/g,
    (match) => {
      return monthAbbreviations[match]
    },
  )
}
