import classNames from 'classnames'
import { PlayIcon } from '@/atoms/Icons/PlayIcon'
import { LegacyImage as Image } from '@/atoms/LegacyImage'

interface SimpleThumbnailProps {
  imageUrl: string
  showButton?: boolean
  className?: string
  onClick: () => void | undefined
  onLoadingComplete?: () => void
}

export const SimpleThumbnail: React.FC<SimpleThumbnailProps> = ({
  imageUrl,
  onClick,
  showButton = true,
  className = '',
  onLoadingComplete,
}) => {
  return (
    <div
      className={classNames('block aspect-w-16 aspect-h-9 w-full relative cursor-pointer overflow-hidden', className)}
      onClick={() => onClick()}
    >
      <Image
        alt="Video player thumbnail"
        src={imageUrl}
        className="absolute top-0 m-auto h-fit"
        layout="fill"
        objectFit="contain"
        onLoadingComplete={onLoadingComplete}
      />
      {showButton && (
        <div className="absolute left-1/2 top-1/2 flex h-fit w-fit -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-white/20 p-4 backdrop-blur-[48px]">
          <PlayIcon size={32} color="white" />
        </div>
      )}
    </div>
  )
}
