import {
  CatalogTitle,
  formatTitleLikeVerticalImagePath,
  findTitleProjectSlug,
  formatWatchPath,
} from '@/services/ContentCatalog'
import { LinkViewModel, LinksViewProps } from '@/services/RenderService'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { cLogger } from '@/utils/logging/client-logger'
import { Grid } from './Grid'
import { LargePortraitLink } from './LargePortraitLink'

export function LargePortraitGrid(props: LinksViewProps) {
  return (
    <Grid>
      {props.links.map((link) => (
        <LargePortraitLink key={link.name} link={link} />
      ))}
    </Grid>
  )
}

LargePortraitGrid.mapTitle = mapTitleToLink

export function mapTitleToLink(title: CatalogTitle): LinkViewModel | undefined {
  const imageUrl = getCloudinaryImageUrl({ path: formatTitleLikeVerticalImagePath(title), width: 294, height: 441 })
  const linkUrl = formatWatchPath(title)

  if (!imageUrl || !linkUrl) {
    cLogger().error(
      `A link view model cannot be created for this title because one or both of imageUrl and linkUrl could not be generated.`,
      { component: LargePortraitGrid.name, title, imageUrl, linkUrl },
    )
    return
  }

  return {
    name: title.title,
    alt: title.title,
    imageUrl,
    linkUrl,
    track: {
      eventName: 'Project Tile Clicked',
      payload: {
        slug: findTitleProjectSlug(title),
      },
    },
  }
}
