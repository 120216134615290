import { LinkViewModel, LinksView, LinksViewProps, MapTitleFn, isHasTypeName } from '@/services/RenderService'
import { PageDataContext } from '@/services/RenderService'
import { CollectionDisplay as CollectionDisplayType } from '@/types/codegen-contentful'
import { cLogger } from '@/utils/logging/client-logger'
import { isTitleCollection, mapContentIdsToTitles } from '../TitleCollection'
import { LandscapeRail, mapToLink } from './LandscapeRail'
import { LargeLandscapeGrid } from './LargeLandscapeGrid'
import { LargePortraitGrid } from './LargePortraitGrid'

interface CollectionDisplayProps {
  collectionDisplay: CollectionDisplayType
  pageDataContext: PageDataContext
}

/**
 * Combines a list of data and a display format in order to render a collection
 */
export function CollectionDisplay({ collectionDisplay, pageDataContext }: CollectionDisplayProps) {
  if (!collectionDisplay.displayAs) return null

  const Ui = mapUiFunction(collectionDisplay.displayAs)

  if (!Ui) {
    cLogger().error(
      `A "CollectionDisplay" was provided with an unsupported "displayAs" option; nothing will be rendered.`,
      { collectionDisplay },
    )
    return null
  }

  const links = mapCollectionLinkData(collectionDisplay, Ui.mapTitle, pageDataContext)

  return <Ui links={links} showTitleDescription={pageDataContext.contentItemConfig?.showThumbnailTitleDescription} />
}

export function isCollectionDisplay(content: unknown): content is CollectionDisplayType {
  return isHasTypeName(content) && content.__typename === 'CollectionDisplay'
}

/**
 * Finds the appropriate data needed for the kind of collection and converts it to the shared view model
 */
function mapCollectionLinkData(
  collectionDisplay: CollectionDisplayType,
  mapTitleFn: MapTitleFn,
  pageDataContext: PageDataContext,
): LinkViewModel[] {
  const collection = collectionDisplay.collection
  if (isTitleCollection(collection)) {
    const titles = mapContentIdsToTitles(pageDataContext, collection.contentCatalogIds)
    const linkViewModels = titles.map(mapTitleFn)
    const validLinkViewModels = linkViewModels.filter(Boolean) as LinkViewModel[]
    return validLinkViewModels
  }

  cLogger().error(`Failed to generate link view models for this collection because its type is not supported.`, {
    collection: collectionDisplay.collection,
  })
  return []
}

/**
 * Matches the displayAs to the appropriate UI.
 *
 * For now displayAs values, add the UI of type LinksView here.
 */
function mapUiFunction(displayAs: string | undefined): LinksView | undefined {
  return {
    'large-portrait-grid': LargePortraitGrid,
    'large-landscape-grid': LargeLandscapeGrid,
    'landscape-rail': LandscapeRail,
    'landscape-small': LandscapeRail,
    landscape: undefined,
    'landscape-with-subtext': LandscapeRailWithSubtext,
    'landscape-small-with-subtext': undefined,
    'landscape-grid-with-subtext': LandscapeGridWithSubtext,
  }[displayAs ?? '']
}

const LandscapeRailWithSubtext = (props: LinksViewProps) => {
  return <LandscapeRail {...props} showTitleDescription={true} />
}

const LandscapeGridWithSubtext = (props: LinksViewProps) => {
  return <LargeLandscapeGrid {...props} showTitleDescription={true} />
}

LandscapeGridWithSubtext.mapTitle = mapToLink
LandscapeRailWithSubtext.mapTitle = mapToLink
