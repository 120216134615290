import React from 'react'
import { TitleAndSubtitleDisplay } from '@/atoms/TitleAndSubtitleDisplay'
import { BasicCarousel } from '@/molecules/BasicCarousel'
import { PlaceholderMediaCard } from '@/molecules/MediaCard'
import {
  CatalogTitle,
  formatTitleLikeLandscapeImagePath,
  findTitleProjectSlug,
  formatWatchPath,
} from '@/services/ContentCatalog'
import { LinkViewModel, LinksViewProps } from '@/services/RenderService'
import { formatCloudinaryPath, getCloudinaryImageUrl } from '@/utils/Cloudinary/cloudinary'
import { cLogger } from '@/utils/logging/client-logger'
import { LandscapeLink } from './LandscapeLink'

export function LandscapeRail(props: LinksViewProps) {
  return (
    <BasicCarousel containerClassName="px-4 sm:px-8 md:px-12 xl:px-16 overflow-x-hidden">
      {!props.links &&
        Array(5)
          .fill(0)
          .map((_v, i) => (
            <PlaceholderMediaCard key={i} hideTextPlaceholders className="mr-4" borderRadiusClassName="rounded-lg" />
          ))}

      {props.links.map((link, i) => {
        const artPath = formatCloudinaryPath(link.imageUrl)
        const isViableRender = !!artPath
        if (!isViableRender) {
          cLogger().warn(
            `An item in a LandscapeRail collection cannot be rendered because it does not have a valid imageUrl.`,
            { link },
          )
          return null
        }

        return (
          <div
            key={`${link.linkUrl}-${i}`}
            className="w-2/3 min-w-[256px] max-w-[300px] shrink-0 snap-center snap-always sm:w-2/5 lg:w-3/5"
          >
            <LandscapeLink link={link} />
            {props.showTitleDescription && (
              <TitleAndSubtitleDisplay className="my-4" title={link.name} subtitle={link.label} clampSubtitle />
            )}
          </div>
        )
      })}
    </BasicCarousel>
  )
}

LandscapeRail.mapTitle = mapToLink

export function mapToLink(title: CatalogTitle): LinkViewModel | undefined {
  const imageUrl = getCloudinaryImageUrl({ path: formatTitleLikeLandscapeImagePath(title) })
  const linkUrl = formatWatchPath(title)

  if (!imageUrl || !linkUrl) {
    cLogger().error(
      `A link view model cannot be created for this title because one or both of imageUrl and linkUrl could not be generated.`,
      { component: LandscapeRail.name, title, imageUrl, linkUrl },
    )
    return
  }

  return {
    name: title.title,
    alt: title.title,
    label: title.description.short,
    imageUrl,
    linkUrl,
    track: {
      eventName: 'Project Tile Clicked',
      payload: {
        slug: findTitleProjectSlug(title),
      },
    },
  }
}
