import { reportErrorToBugsnag } from '../bugsnag'

const fallbackAspectRatio = 16 / 9

export type AspectRatio = `${number}:${number}`

export function getNumericAspectRatio(aspect: AspectRatio): number {
  const parts = aspect.split(':')
  if (parts.length !== 2) {
    reportErrorToBugsnag(`Invalid aspect ratio format. Expected "N:D" but got: "${aspect}"`)
    return fallbackAspectRatio
  }

  const numerator = parseInt(parts[0])
  const denominator = parseInt(parts[1])

  if (isNaN(numerator) || isNaN(denominator)) {
    reportErrorToBugsnag(`Invalid aspect ratio. Expected numbers in the format of "N:D" but got: "${aspect}"`)
    return fallbackAspectRatio
  }

  return numerator / denominator
}

export function getFinalDimensions({
  width,
  height,
  aspectRatio,
}: {
  width?: number
  height?: number
  aspectRatio?: number
}): { width: number; height: number } {
  if (aspectRatio) {
    if (width) return { width, height: width / aspectRatio }
    if (height) return { width: height * aspectRatio, height }
  }

  if (typeof width === 'number' && typeof height === 'number') {
    return { width, height }
  }

  reportErrorToBugsnag(
    `Invalid dimension arguments provided. 2 of the 3 properties must be provided. Inputs: ${JSON.stringify({
      width,
      height,
      aspectRatio,
    })}`,
  )

  return { width: 0, height: 0 }
}
