import { gql } from '@apollo/client'

export const GET_PROJECT_V2S_COMING_SOON = gql`
  query fetchProjectv2sComingSoon {
    projectV2sComingSoon {
      id
      slug
      name
      highestScore
      primaryFlow {
        flowPhases {
          phaseSlugEnum
          releaseWindows {
            start
            end
            startSpecificity
            endSpecificity
          }
        }
      }
    }
  }
`

export const GET_PROJECT_V2_BY_SLUG_FOR_PROJECT_JOURNEY = gql`
  query fetchProjectV2BySlugForProjectJourney($projectSlug: String!) {
    projectV2BySlug(slug: $projectSlug) {
      id
      slug
      name
      primaryFlow {
        id
        name
        insertedAt
        flowPhases {
          id
          status
          updatedAt
          phaseName
          phaseSlugEnum
          departmentSlugEnum
        }
      }
    }
  }
`
