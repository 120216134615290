import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const PlayIcon: React.FC<OneColorIconProps> = ({ className, color = 'core-gray-100', size = 12 }) => {
  const hex = getHexColor(color)

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.95802 19.7776C8.53004 20.0573 8.09649 20.0732 7.65738 19.8253C7.21913 19.5784 7 19.1967 7 18.6803V5.32001C7 4.80367 7.21913 4.42158 7.65738 4.17374C8.09649 3.92675 8.53004 3.9431 8.95802 4.22279L19.4222 10.903C19.8074 11.1611 20 11.5269 20 12.0002C20 12.4735 19.8074 12.8392 19.4222 13.0974L8.95802 19.7776Z"
        fill={hex}
      />
    </svg>
  )
}
