import { useCallback } from 'react'
import { Attributes, Experiment, useExperiment, useFeatureValue, useGrowthBook } from '@growthbook/growthbook-react'
import { AppFeatures } from './app-features'

export function useGBExperiment<T extends keyof AppFeatures>(key: T, defaultValue: AppFeatures[T]) {
  const growthbook = useGrowthBook()
  const value = useFeatureValue<AppFeatures[T]>(key, defaultValue)

  return [value, growthbook.ready] as const
}

export function useExperimentContext() {
  const growthbook = useGrowthBook()

  const resolvedSetAttributes = useCallback(
    (attributes: Attributes) => {
      growthbook.setAttributes({ ...growthbook.getAttributes(), ...attributes })
    },
    [growthbook],
  )

  const getFeatureValue = useCallback(
    <T extends keyof AppFeatures>(key: T, defaultValue: AppFeatures[T]) => {
      return growthbook.getFeatureValue(key, defaultValue)
    },
    [growthbook],
  )

  return {
    getUserAttributes: growthbook.getAttributes,
    setUserAttributes: resolvedSetAttributes,
    getFeatureValue,
    ready: growthbook.ready,
  }
}

export function useInlineExperiment<T>(exp: Experiment<T>) {
  const { value } = useExperiment(exp)

  return value
}
