import Bugsnag from '@bugsnag/js'
import { FetchProjectv2sComingSoonQuery } from '@/types/codegen-federation'
import { getWebClient } from '../ApolloClient'
import { GET_PROJECT_V2S_COMING_SOON } from './queries'

export const getProjectV2sComingSoon = async () => {
  try {
    const client = getWebClient()
    const { data } = await client.query<FetchProjectv2sComingSoonQuery>({
      query: GET_PROJECT_V2S_COMING_SOON,
    })
    return data?.projectV2sComingSoon ?? []
  } catch (error) {
    Bugsnag.notify(error as Error)
    return []
  }
}
