import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { PlayCircleIcon } from '@/atoms/Icons/PlayCircleIcon'
import { InternalLink } from '@/atoms/InternalLink'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { TitleXS } from '@/atoms/Text'
import { getFinalDimensions } from '@/utils/aspectRatio/aspectRatio'

type Gradient = 'radial' | 'linear' | 'none'

const gradients = {
  linear: {
    backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 68.54%, rgba(0, 0, 0, 0.5) 100%)',
    inset: '0px',
  },
  radial: {
    backgroundImage: 'radial-gradient(farthest-corner at 40% 20%, transparent, transparent, #151515FF)',
    inset: '0px',
  },
}

const getGradient = (gradient: Gradient) => {
  switch (gradient) {
    case 'linear':
    case 'radial':
      return gradients[gradient]
    default:
      return undefined
  }
}

export interface ThumbnailProps {
  aspectRatio?: number
  className?: string
  cloudinaryImagePath: string
  gradient?: Gradient
  href: string
  imageHeight?: number
  imageWidth?: number
  includePlayCircle?: boolean
  logo?: {
    src: string
    alt: string
    className?: string
  }
  objectPositionClassName?: string
  onClick?: () => void
  posterTransformation?: string
  showTitle?: boolean
  title?: string
}

export const Thumbnail: React.FC<ThumbnailProps> = ({
  aspectRatio = 16 / 9,
  className,
  cloudinaryImagePath,
  gradient = 'radial',
  href,
  imageHeight = 180,
  imageWidth = 320,
  includePlayCircle = true,
  logo,
  onClick,
  posterTransformation = `ar_16:9,c_thumb,q_auto,g_north,w_${imageWidth},h_${imageHeight}`,
  showTitle = false,
  title,
}) => {
  const [isLoadingImage, setLoadingImage] = useState(true)
  const [logoAspectRatio, setLogoAspectRatio] = useState(aspectRatio)
  const onLoadComplete = useCallback(() => setLoadingImage(false), [])
  const thumbnailGradient = getGradient(gradient)
  const showWebP = logo?.src?.endsWith('.webp') ? '' : '.webp'

  const { width, height } = getFinalDimensions({ width: imageWidth, height: imageHeight, aspectRatio })
  const logoWidth = width * 0.8

  return (
    <div onClick={onClick} className={classNames(className)}>
      <InternalLink href={href}>
        <div
          className={classNames([
            'relative w-full bg-black rounded-lg group-thumbnail overflow-hidden',
            'duration-[400ms] ease-in-out',
            { 'animate-pulse': isLoadingImage },
          ])}
          style={{ boxShadow: 'rgb(0 0 0 / 40%) 0px 24px 32px -8px, rgb(0 0 0 / 40%) 0px 12px 12px -8px' }}
        >
          <Image
            alt={title}
            width={width}
            height={height}
            layout="responsive"
            onLoadingComplete={onLoadComplete}
            src={`/${posterTransformation}/${cloudinaryImagePath}.webp`}
          />
          <div className="absolute inset-0">
            {includePlayCircle && (
              <div
                className={classNames(
                  'w-full h-full flex justify-center items-center opacity-0 transition',
                  'duration-[400ms] ease-in-out',
                  'group-thumbnail-hover:opacity-100 group-thumbnail-hover:bg-[#00000088]',
                )}
              >
                <div className="mx-auto h-fit w-fit !opacity-100">
                  <PlayCircleIcon />
                </div>
              </div>
            )}
            {logo && (
              <>
                {thumbnailGradient && <div className="absolute bottom-0 w-full" style={thumbnailGradient} />}
                <div className={classNames('absolute', logo.className ?? 'flex items-end bottom-2 right-2 w-1/3')}>
                  <Image
                    alt={logo.alt}
                    src={`${logo.src}${showWebP}`}
                    width={logoWidth}
                    height={logoWidth / logoAspectRatio}
                    objectFit="contain"
                    onLoadingComplete={({ naturalWidth, naturalHeight }) => {
                      setLogoAspectRatio(naturalWidth / naturalHeight)
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </InternalLink>
      {showTitle && <TitleXS className="mt-4">{title}</TitleXS>}
    </div>
  )
}
