import isObject from 'lodash/isObject'
import {
  CollectionModel,
  ContentExperimentModel,
  HasTypeName,
  InlineExperimentModel,
  PageModel,
  UltrawideBannerGroupModel,
} from '@/services/RenderService/types'
import { FeaturedProject } from '@/types/codegen-contentful'

export function isCollectionModel(content: unknown): content is CollectionModel {
  return isHasTypeName(content) && content.__typename === 'Collection'
}

export function isUltrawideBannerGroupModel(content: unknown): content is UltrawideBannerGroupModel {
  return isHasTypeName(content) && content.__typename === 'UltrawideBannerGroup'
}

export function isPageModel(content: unknown): content is PageModel {
  return isHasTypeName(content) && content.__typename === 'Page'
}

export function isContentExperimentModel(content: unknown): content is ContentExperimentModel {
  return isHasTypeName(content) && content.__typename === 'ContentExperiment'
}

export function isInlineExperimentModel(content: unknown): content is InlineExperimentModel {
  return isHasTypeName(content) && content.__typename === 'InlineExperiment'
}

export function isHasTypeName(content: unknown): content is HasTypeName {
  /* eslint-disable no-prototype-builtins */
  return isObject(content) && content.hasOwnProperty('__typename')
}

export function isFeaturedProject(content: unknown): content is FeaturedProject {
  return isHasTypeName(content) && content.__typename === 'FeaturedProject'
}
