import React from 'react'
import { AppFeatures } from '../app-features'

type Keys = keyof AppFeatures
export type Values = AppFeatures[Keys]

export type Props =
  | { variation: Values; children: React.ReactNode; default?: never }
  | { default: true; children: React.ReactNode; variation?: never }

export const ExperimentVariation = (props: Props) => props.children
