import { getProjectContentLinks, getStartWatchingList } from '@/services/ProjectsService'
import {
  mapProjectsToLogoContentLinks,
  mapStartWatchingToContentLinks,
} from '@/services/RenderService/mapCollectionDataSources'
import { MapDataOptions } from '@/services/RenderService/types'

export async function getBrandLogos(options?: MapDataOptions) {
  const projectLinks = await getProjectContentLinks()
  return mapProjectsToLogoContentLinks(projectLinks, options)
}

export async function getStartWatchingEpisodes(options?: MapDataOptions) {
  const projects = await getStartWatchingList()
  return mapStartWatchingToContentLinks(projects, options)
}
