import numeral from 'numeral'

interface FormatNumberForDisplayProps {
  count: number
  format?: string
}

export function formatNumberForDisplay({ count, format = '0.0a' }: FormatNumberForDisplayProps): string {
  if (count < 1000) {
    if (format !== '0.0a') {
      return numeral(count).format(format)
    }
    return count.toString()
  }

  return numeral(count).format(format)
}

export function randomIntFromInterval(minIncluded: number, maxIncluded: number) {
  return Math.floor(Math.random() * (maxIncluded - minIncluded + 1) + minIncluded)
}

/**
 * Chooses 'st', 'nd', 'rd, 'th', as appropriate
 */
export function formatOrderSuffix(n: number): string {
  const ones = n % 10
  const tens = n % 100
  if (ones === 1 && tens !== 11) {
    return 'st'
  }
  if (ones === 2 && tens !== 12) {
    return 'nd'
  }
  if (ones === 3 && tens !== 13) {
    return 'rd'
  }
  return 'th'
}
