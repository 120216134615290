import Bluebird from 'bluebird'
import { fetchTitlesForPage, isRequiresTitleMapOnPage } from '@/page/TitleCollection'
import {
  DataSourceType,
  getBrandLogos,
  getCollectionDataSources,
  getStartWatchingEpisodes,
  LinkViewModel,
  PageDataContext,
} from '@/services/RenderService'
import { Page } from '@/types/codegen-contentful'
import { reportWarningToBugsnag } from '@/utils/bugsnag'
import { isStagingEnvironment } from '@/utils/environment-utils'
import { omitUndefineds } from '@/utils/object'
import { createWebClient } from '../ApolloClient'
import { getCollection, SNEAK_PEEK_ENTRY_ID } from '../CmsService/Collection'
import { getComingSoonProjects, getComingSoonTab } from '../ComingSoonService'
import {
  CatalogTitle,
  CatalogTitleMap,
  findHydraExternalId,
  findTitleProjectSlug,
  formatTitleMap,
  isTitleLinkedToEpisodeWatchPage,
} from '../ContentCatalog'
import { getEpisode, getProjectAbout } from '../ProjectsService'

export async function fetchPageDataContext(
  page: Page,
  preview: boolean = false,
  locale: string = 'en',
): Promise<PageDataContext | null> {
  if (!page.contentCollection?.items || page.contentCollection?.items.length === 0) {
    return {
      preview,
    }
  }

  const dataSources = getCollectionDataSources(page)
  const dataForPage: PageDataContext = {
    preview,
  }

  for (const dataSource of dataSources) {
    if (dataSource === 'brand-logos') {
      const brandLogos = await maybeFetchDataSource<LinkViewModel[]>('brand-logos', page)
      dataForPage['brand-logos'] = brandLogos
    }
    if (dataSource === 'coming-soon') {
      const upcomingProjects = await getComingSoonProjects(locale)
      const upcomingTabProjects = await getComingSoonTab()
      dataForPage['coming-soon'] = upcomingProjects
      dataForPage['coming-soon-tab'] = upcomingTabProjects
    } else if (dataSource === 'early-access') {
      const sneakPeekProjects = await getCollection({ locale, preview }, SNEAK_PEEK_ENTRY_ID)
      dataForPage['sneak-peeks'] = sneakPeekProjects
    } else if (dataSource === 'start-watching') {
      const startWatchingEpisodes = await maybeFetchDataSource<LinkViewModel[]>('start-watching', page)
      dataForPage['start-watching'] = startWatchingEpisodes
    }
  }

  if (isRequiresTitleMapOnPage(page)) {
    const titles = await fetchTitlesForPage(page)
    dataForPage['title-map'] = await getValidatedTitleMap(titles)
  }

  return omitUndefineds(dataForPage)
}

/**
 *
 * The data coming back from the Content Catalog is, unfortunately, often invalid.
 * We're patching this on the front-end for now by detecting and removing the invalid titles here.
 * @returns
 */
export async function getValidatedTitleMap(titles: CatalogTitle[]): Promise<CatalogTitleMap> {
  const client = createWebClient()

  const validated = await Bluebird.map(
    titles,
    async (title) => {
      const projectSlug = findTitleProjectSlug(title)

      if (!projectSlug) return

      try {
        if (isTitleLinkedToEpisodeWatchPage(title)) {
          const guid = findHydraExternalId(title)
          const response = await getEpisode({ guid, projectSlug }, client)

          if (response && response.episode) {
            return title
          } else {
            reportWarning(
              `Unable to find an episode from the federated graph for a Content Catalog title. title: "${title.title}" id: "${title.id}"`,
            )
            return
          }
        } else {
          const response = await getProjectAbout({ slug: projectSlug }, client)

          if (response && response.slug === projectSlug) {
            return title
          } else {
            reportWarning(
              `Unable to find a project from the federated graph for a Content Catalog title. title: "${title.title}" id: "${title.id}"`,
            )
            return
          }
        }
      } catch (err) {
        reportWarning(
          `An error occurred while attempting to find an associated episode for a Content Catalog title. title: "${title.title}" id: "${title.id}" error: ${err}`,
        )
        return
      }
    },
    { concurrency: 3 },
  )

  return formatTitleMap(validated.filter(Boolean) as CatalogTitle[])
}

function reportWarning(msg: string) {
  if (!isStagingEnvironment()) {
    reportWarningToBugsnag(msg)
  }
}

async function maybeFetchDataSource<T>(dataSource: DataSourceType, page: Page): Promise<T> {
  const dataSources = getCollectionDataSources(page)
  if (!dataSources.has(dataSource)) return Promise.resolve([] as T)

  switch (dataSource) {
    case 'brand-logos':
      return (await getBrandLogos({ analytics: { page } })) as T
    case 'start-watching':
      return (await getStartWatchingEpisodes({ analytics: { page } })) as T
    default:
      return Promise.resolve([] as T)
  }
}
